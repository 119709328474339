<template>
  <b-container fluid>
    <div
      v-if="contractsLoading"
      class="d-flex justify-content-center mb-3 mt-5"
    >
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        type="grow"
        variant="primary"
      ></b-spinner>
      <span class="h4 mt-2 text-primary"> {{ $t("app.loading") }}... </span>
    </div>
    <b-row v-else-if="contract">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <div>
              <span class="h4"
                >{{ $t("contracts.contract") }}
                {{ contract.ad_service.name }}</span
              >
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="3">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("contracts.details") }}</h4>
          </template>
          <template v-slot:body>
            <div>
              <strong
                ><i class="ri-global-line"></i> {{ $t("contracts.service") }}:
              </strong>
              {{ contract.ad_service.name }}
            </div>
            <div>
              <span>
                <strong
                  ><i class="ri-global-line"></i>
                  {{ $t("contracts.expired-date") }}:
                </strong>
                {{ moment(contract.expired_date).format("DD/MM/YYYY") }} </span
              ><br />
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col md="12">
            <list-advertisings></list-advertisings>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ListAdvertisings from "../Advertisings";
import moment from "moment";
export default {
  components: {
    ListAdvertisings,
  },
  mounted() {
    this.fetchContract(this.$route.params.id);
  },
  data() {
    return {
      moment,
      isBusy: false,
    };
  },
  methods: {
    ...mapActions({
      fetchContract: "fetchContract",
    }),
  },
  computed: {
    ...mapGetters({
      contract: "contract",
      contractsLoading: "contractsLoading",
    }),
  },
};
</script>
