<template>
  <section>
    <b-overlay :show="advertisingsLoading" rounded="sm">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="">
            {{ $t("advertisings.index") }}
          </h4>
        </template>
        <template v-slot:headerAction>
          <b-button
            class="mr-1 float-right"
            @click="$refs.modalAdvertising.show(null)"
            variant="primary"
            >{{ $t("advertisings.add-advertising") }}</b-button
          >
        </template>
        <template v-slot:body>
          <b-row>
            <b-col sm="12" md="1" class="pr-1 mb-2">
              <b-form-select
                :options="['5', '10', '20', '50', '100']"
                v-model="filter.per_page"
                size="sm"
                @input="getData()"
              ></b-form-select>
            </b-col>
            <b-col lg="3" md="6" sm="12" class="ml-auto mb-2">
              <b-form-group>
                <b-form-input
                  class="border"
                  style="height: 35px"
                  id="filter-input"
                  v-model="filter.search"
                  type="search"
                  :placeholder="$t('app.search')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="table-responsive">
            <b-table
              class="table mb-0 table-borderless"
              :items="advertisings.data"
              :fields="columns"
            >
              <template v-slot:cell(image)="data">
                <b-img
                  v-if="data.item.image"
                  :src="data.item.image"
                  class="img-fluid rounded"
                  width="120px"
                  alt="Advertising"
                />
              </template>
              <template v-slot:cell(created_at)="data">
                {{ moment(data.item.created_at).format("DD-MM-YYYY HH:mm") }}
                hrs.
              </template>
              <template v-slot:cell(action)="data">
                <b-button
                  variant=" iq-bg-warning rounded"
                  class="mr-1"
                  size="sm"
                  @click="$refs.modalAdvertising.show(data.item)"
                  ><i class="ri-ball-pen-fill ml-1"></i
                ></b-button>
                <b-button
                  @click="deleteItem(data.item.id)"
                  variant=" iq-bg-danger rounded"
                  size="sm"
                  ><i class="ri-delete-bin-line ml-1"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              class="mt-3 float-right"
              v-model="filter.page"
              :total-rows="advertisings.total"
              :per-page="advertisings.per_page"
              first-number
              pills
              size="sm"
              @change="getData"
            ></b-pagination>
            <p class="mt-3">
              {{ $t("app.registers") }} {{ advertisings.to }}
              {{ $t("app.of") }} {{ advertisings.total }}. Total
              {{ advertisings.total }}
            </p>
          </div>
        </template>
      </iq-card>
    </b-overlay>
    <!-- Modal Form -->
    <modal-form-advertising ref="modalAdvertising" @refresh="getData()" />
  </section>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalFormAdvertising from "./createOrUpdateAdvertising.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
export default {
  name: "Advertisings",
  components: {
    modalFormAdvertising,
  },
  async mounted() {
    core.index();
    this.debouncedGetAdvertisings = _.debounce(this.getData, 500);
    await this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetAdvertisings();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchAdvertisings: "fetchAdvertisings",
      deleteAdvertising: "deleteAdvertising",
    }),
    updateItem(data) {
      this.$refs.modalAdvertising.show(data);
    },
    async getData(page = 1) {
      this.filter.page = page;
      await this.fetchAdvertisings({
        ...this.filter,
        ad_contract_id: this.$route.params.id,
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteAdvertising(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      filter: {
        paginate: true,
        page: 1,
        per_page: 10,
        search: null,
      },
      totalRows: 1,
      moment,
    };
  },
  computed: {
    ...mapGetters({
      advertisings: "advertisings",
      advertisingsLoading: "advertisingsLoading",
    }),
    columns() {
      return [
        {
          label: this.$t("app.image"),
          key: "image",
          class: "text-center",
        },
        {
          label: this.$t("advertisings.name"),
          key: "name",
          class: "text-center",
        },
        {
          label: this.$t("advertisings.created_at"),
          key: "created_at",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
<style>
.content-width {
  max-width: 460px !important;
}
</style>
